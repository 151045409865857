import { XF } from "./XF";
import { CollapsiblePosts } from "./collapsible-posts";
// Script for handling the loading and hiding of extra post replies

document.addEventListener("DOMContentLoaded", () => {
  attachLoadListeners();
});

function attachLoadListeners() {
  const loadButtons = document.querySelectorAll(".js-nested-reply-label");

  loadButtons.forEach((button) => {
    // Remove existing listeners (if any)
    button.removeEventListener("click", loadMoreClick);

    button.addEventListener("click", loadMoreClick);
  });
}

async function loadMoreClick(event: Event) {
  const target = <HTMLButtonElement>event.currentTarget;
  const buttonSiblings = target?.parentElement?.children;
  if (!buttonSiblings) {
    return;
  }
  let parentContainer: null | Element = null;
  for (let i = 0; i < buttonSiblings?.length; i++) {
    if (
      buttonSiblings
        ?.item(i)
        ?.classList.contains("js-nested-children-container")
    ) {
      parentContainer = buttonSiblings.item(i);
      break;
    }
  }

  if (target?.getAttribute("all-posts-loaded") === "true") {
    parentContainer?.classList.toggle("hidden");
    target.classList.toggle("hidden");
    target.nextElementSibling?.classList.toggle("hidden");
  } else {
    // Otherwise, fetch posts that haven't been loaded yet
    const postsPromise = fetchPostHtml(target);
    postsPromise.then((postsHtml) => {
      if (postsHtml == null) {
        return;
      }

      if (parentContainer) {
        XF.setupHtmlInsert(postsHtml, ($html) => {
          $(<Element>parentContainer).append($html);
          updateReplyCount(target);

          // Attach listeners to any newly created buttons
          attachLoadListeners();
          attachCollapsedPostListeners(<Element>parentContainer);
          return true;
        });
      }
    });
  }
}

async function fetchPostHtml(loadMoreButton: HTMLButtonElement) {
  const postsLoaded = readNumberFromAttribute(loadMoreButton, "posts-loaded");
  const totalReplyCount = readNumberFromAttribute(
    loadMoreButton,
    "total-reply-count"
  );
  const exactReplyCountUnknown = loadMoreButton.getAttribute(
    "exact-reply-count-unknown"
  );

  const insertedPostIdsEl = document.getElementById(
    "js-inserted-post-ids"
  ) as HTMLInputElement;
  const insertedPostIds = insertedPostIdsEl?.value;

  const urlParams = new URLSearchParams(window.location.search);
  const tlParam = urlParams.has("tl") ? { tl: urlParams.get("tl") } : {};

  const resp = await XF.ajax(
    "get",
    "/threads/load-more-posts/",
    {
      parent_post_id: loadMoreButton.getAttribute("parent-post"),
      parent_post_level: loadMoreButton.getAttribute("parent-level"),
      thread_id: loadMoreButton.getAttribute("thread-id"),
      total_reply_count: totalReplyCount,
      exact_reply_count_unknown: exactReplyCountUnknown,
      posts_loaded: postsLoaded,
      inserted_post_ids: insertedPostIds,
      sortby: urlParams.get("sortby"),
      ...tlParam,
    },
    undefined,
    { skipDefault: true }
  );

  // TODO: hook this up to observability framework
  // Button has sent a faulty response, hide it for now
  if (!resp.html?.content) {
    loadMoreButton.classList.add("hidden");
    return null;
  }
  return resp.html.content;
}

function attachCollapsedPostListeners(parentContainer: Element) {
  CollapsiblePosts.init(parentContainer);
}

function updateReplyCount(button: HTMLButtonElement) {
  const exactReplyCountUnknown = button.getAttribute(
    "exact-reply-count-unknown"
  );

  let totalReplyCount;
  let oldLeftToLoadCount;

  if (exactReplyCountUnknown) {
    const totalReplyCountEl = document.getElementById(
      "js-total-reply-count"
    ) as HTMLInputElement;
    const totalReplyCountStr = totalReplyCountEl?.value;
    button.setAttribute("total-reply-count", totalReplyCountStr);
    totalReplyCount = parseInt(totalReplyCountStr, 10);
    totalReplyCountEl.remove();
    oldLeftToLoadCount = totalReplyCount;
    // now the exact number is calculated
    button.removeAttribute("exact-reply-count-unknown");
  } else {
    totalReplyCount = readNumberFromAttribute(button, "total-reply-count");
    const leftToLoadCountElement = <HTMLElement>(
      button.querySelector(".js-left-to-load")
    );
    oldLeftToLoadCount = parseInt(leftToLoadCountElement.innerHTML, 10);
  }

  const loadedBatchSizeEl = document.getElementById(
    "js-loaded-batch-size"
  ) as HTMLInputElement;
  const loadedBatchSize = parseInt(loadedBatchSizeEl?.value, 10);
  loadedBatchSizeEl.remove();

  const newLeftToLoadCount = oldLeftToLoadCount - loadedBatchSize;

  if (newLeftToLoadCount <= 0) {
    // All posts loaded, time to show the "hide replies" button
    button.classList.toggle("hidden");
    button.setAttribute("all-posts-loaded", "true");

    updateInnerHTML(button, totalReplyCount);

    applyConnectorHidingClassToButton(button);
  } else {
    // Still have posts to load
    updateInnerHTML(button, newLeftToLoadCount);
  }

  button.setAttribute(
    "posts-loaded",
    String(totalReplyCount - newLeftToLoadCount)
  );
}

function updateInnerHTML(
  button: HTMLButtonElement,
  newLeftToLoadCount: number
) {
  const leftToLoadCountElement = button.querySelector(".js-left-to-load");
  const replyTextElement = button.querySelector(".js-reply-text");
  if (leftToLoadCountElement) {
    leftToLoadCountElement.innerHTML = String(newLeftToLoadCount);
  }
  if (replyTextElement) {
    replyTextElement.innerHTML = newLeftToLoadCount === 1 ? "Reply" : "Replies";
  }
}

function readNumberFromAttribute(
  element: HTMLElement,
  attribute: string,
  defaultValue = 0
): number {
  const number = parseInt(element.getAttribute(attribute) ?? "", 10);
  return Number.isNaN(number) ? defaultValue : number;
}

// Made for CU-291 to remove the hide reply buttons, only allowing for expansion of replies
function applyConnectorHidingClassToButton(button: HTMLElement) {
  const buttonSiblings = [].slice.call(button?.parentElement?.children);
  const buttonPostSiblings = buttonSiblings.filter((sibling: HTMLElement) =>
    sibling?.classList.contains("js-nested-children-container")
  );

  if (buttonPostSiblings) {
    const elementToHideConnector = <HTMLElement>(
      buttonPostSiblings[buttonPostSiblings.length - 1]
    );

    elementToHideConnector.classList.add("hide-last-message-connector");
    elementToHideConnector.classList.add("hide-last-wrapper-connector");
  }
}
